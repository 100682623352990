<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-header border-0">
            <div class="card-title">
              <h3 class="card-label">Bills</h3>
            </div>
            <div class="card-toolbar">
              <button
                @click.prevent="exportAsExcel"
                class="btn btn-light-primary font-weight-bold px-10"
              >
                {{ $t('MAIN_MENU.EXPORT') }} .xlsx
              </button>
            </div>
          </div>
          <div class="card-body py-0">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-xl mb-3 mb-xl-0">
                <div class="input-group">
                  <input
                    v-model="imei"
                    type="text"
                    class="form-control form-control-solid form-control-lg"
                    :placeholder="$t('BILLS.ENTER_DEVICE_IMEI')"
                  />
                </div>
              </div>
              <DatePicker
                v-model="range"
                mode="dateTime"
                :masks="masks"
                locale="hr"
                is-range
                is24hr
                :columns="$screens({ default: 1, lg: 1 })"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="d-flex">
                    <div>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <i class="la la-calendar"></i>
                          </span>
                        </div>
                        <input
                          class="form-control form-control-solid form-control-lg"
                          :value="inputValue.start"
                          v-on="inputEvents.start"
                          :placeholder="fromText"
                        />
                      </div>
                    </div>
                    <div class="flex-shrink-0 my-2 mx-3">
                      <i class="la la-arrow-right"></i>
                    </div>
                    <div>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <i class="la la-calendar"></i>
                          </span>
                        </div>
                        <input
                          class="form-control form-control-solid form-control-lg"
                          :value="inputValue.end"
                          v-on="inputEvents.end"
                          :placeholder="toText"
                        />
                      </div>
                    </div>
                  </div>
                </template>
              </DatePicker>
              <div class="col-sm-12 col-md-12 col-xl mb-3 mb-xl-0">
                <button
                  @click.prevent="update"
                  class="btn btn-light-primary btn-block"
                  style="height: 44px"
                >
                  {{ $t('GENERAL.UPDATE') }}
                </button>
              </div>
            </div>
            <div class="separator separator-dashed my-5"></div>
            <b-table
              striped
              responsive
              sticky-header="72vh"
              :items="bills"
              :fields="fields"
              :busy="!isLoaded"
            >
              <template #cell(status)="data">
                <span class="label label-sm label-success p-3"></span>
              </template>
              <template #cell(businessSpaceLabel)="data">
                <span>{{ data.item.businessSpaceLabel }}</span>
              </template>
              <template #table-busy>
                <div class="text-center my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-4">{{ $t('GENERAL.LOADING') }}</strong>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import format from 'date-fns/format'
import { mapGetters } from 'vuex'
import axios from 'axios'
import parse from 'date-fns/parse'

export default {
  name: 'BillsList',
  components: {
    DatePicker,
  },
  data() {
    return {
      fields: [
        {
          key: 'status',
          label: 'STATUS',
          sortable: true,
        },
        {
          key: 'jir',
          label: 'JIR',
          sortable: false,
        },
        {
          key: 'zki',
          label: 'ZKI',
          sortable: false,
        },
        {
          key: 'imei',
          label: 'IMEI',
          sortable: true,
        },
        {
          key: 'businessSpaceLabel',
          label: 'PP',
          sortable: true,
        },
        {
          key: 'amount',
          label: 'Iznos',
          sortable: true,
        },
        {
          key: 'createdAt',
          label: 'Datum i vrijeme',
          sortable: true,
          class: 'text-right',
        },
      ],
      bills: [],
      totalItems: null,
      isLoaded: false,
      range: {
        start: null,
        end: null,
      },
      masks: {
        input: 'dd.MM.yyyy - HH:mm:ss',
      },
      lastTransaction: null,
      fromText: '',
      toText: '',
      imei: null,
    }
  },
  computed: {
    ...mapGetters(['currentUserRole', 'currentUserCompanyOib']),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Bills', route: '/transactions/list' },
    ])

    if (
      this.currentUserRole === 'ROLE_ROOT' ||
      this.currentUserRole === 'ROLE_SUPER_ADMIN'
    ) {
      axios
        .get(
          `${process.env.VUE_APP_BILLS_API_URL}/api/v1/bills?order[createdAt]=desc&pagination=false`
        )
        .then(({ data }) => {
          this.totalItems = data['hydra:member'].length
          this.bills = data['hydra:member'].map((bill) => ({
            jir: bill.jir,
            zki: bill.zki,
            imei: bill.imei,
            businessSpaceLabel: bill.meta.business_space_label,
            amount: bill.amount + ' €',
            createdAt: format(
              new Date(bill.createdAt),
              'dd.MM.yyyy @ HH:mm:ss'
            ),
          }))
          this.isLoaded = true
          this.lastBill = this.bills[this.bills.length - 1]
          this.fromText = this.lastBill.createdAt
          this.toText = 'now'
        })
    }

    if (this.currentUserRole === 'ROLE_ADMIN') {
      axios
        .get(
          `${process.env.VUE_APP_BILLS_API_URL}/api/v1/bills?oib=${this.currentUserCompanyOib}&order[createdAt]=desc&pagination=false`
        )
        .then(({ data }) => {
          this.totalItems = data['hydra:member'].length
          this.bills = data['hydra:member'].map((bill) => ({
            jir: bill.jir,
            zki: bill.zki,
            imei: bill.imei,
            businessSpaceLabel: bill.meta.business_space_label,
            amount: bill.amount + ' €',
            createdAt: format(
              new Date(bill.createdAt),
              'dd.MM.yyyy @ HH:mm:ss'
            ),
          }))
          this.isLoaded = true
          this.lastBill = this.bills[this.bills.length - 1]
          this.fromText = this.lastBill.createdAt
          this.toText = 'now'
        })
    }
  },
  methods: {
    update() {
      let query = `?oib=${this.currentUserCompanyOib}&order[createdAt]=desc&pagination=false`

      if (this.imei && this.range.start === null) {
        query += `&imei=${this.imei}`
      }

      if (this.imei && this.range.start !== null) {
        const after = format(this.range.start, "yyyy-MM-dd'T'HH:mm:ss")
        const before = format(this.range.end, "yyyy-MM-dd'T'HH:mm:ss")
        query += `&imei=${this.imei}&createdAt[after]=${after}&createdAt[before]=${before}`
      }

      if (this.imei === null && this.range.start !== null) {
        const after = format(this.range.start, "yyyy-MM-dd'T'HH:mm:ss")
        const before = format(this.range.end, "yyyy-MM-dd'T'HH:mm:ss")
        query += `&createdAt[after]=${after}&createdAt[before]=${before}`
      }

      this.totalItems = 0
      this.bills = []
      axios
        .get(`${process.env.VUE_APP_BILLS_API_URL}/api/v1/bills${query}`)
        .then(({ data }) => {
          this.totalItems = data['hydra:member'].length
          this.bills = data['hydra:member'].map((bill) => ({
            jir: bill.jir,
            zki: bill.zki,
            imei: bill.imei,
            businessSpaceLabel: bill.meta.business_space_label,
            amount: bill.amount + ' €',
            createdAt: format(
              new Date(bill.createdAt),
              'dd.MM.yyyy @ HH:mm:ss'
            ),
          }))
          this.isLoaded = true
          this.lastBill = this.bills[this.bills.length - 1]
          this.fromText = this.lastBill.createdAt
          this.toText = 'now'
        })
    },
    exportAsExcel() {
      let url = `${process.env.VUE_APP_BILLS_API_URL}/api/v1/bills/export/${this.currentUserCompanyOib}/`

      if (this.imei && this.range.start === null) {
        let firstBillDateTime = parse(
          this.bills[0]['createdAt'],
          'dd.MM.yyyy @ HH:mm:ss',
          new Date()
        )
        firstBillDateTime = format(firstBillDateTime, "yyyy-MM-dd'T'HH:mm")

        let lastBillDateTime = parse(
          this.bills[this.bills.length - 1]['createdAt'],
          'dd.MM.yyyy @ HH:mm:ss',
          new Date()
        )
        lastBillDateTime = format(lastBillDateTime, "yyyy-MM-dd'T'HH:mm")
        url += `${lastBillDateTime}/${firstBillDateTime}/${this.imei}`
      }

      if (this.imei && this.range.start !== null) {
        const after = format(this.range.start, "yyyy-MM-dd'T'HH:mm:ss")
        const before = format(this.range.end, "yyyy-MM-dd'T'HH:mm:ss")
        url += `${after}/${before}/${this.imei}`
      }

      if (this.imei === null && this.range.start !== null) {
        const after = format(this.range.start, "yyyy-MM-dd'T'HH:mm:ss")
        const before = format(this.range.end, "yyyy-MM-dd'T'HH:mm:ss")
        url += `${after}/${before}/-1`
      }

      if (this.imei === null && this.range.start === null) {
        let firstBillDateTime = parse(
          this.bills[0]['createdAt'],
          'dd.MM.yyyy @ HH:mm:ss',
          new Date()
        )
        firstBillDateTime = format(firstBillDateTime, "yyyy-MM-dd'T'HH:mm")

        let lastBillDateTime = parse(
          this.bills[this.bills.length - 1]['createdAt'],
          'dd.MM.yyyy @ HH:mm:ss',
          new Date()
        )
        lastBillDateTime = format(lastBillDateTime, "yyyy-MM-dd'T'HH:mm")
        url += `${lastBillDateTime}/${firstBillDateTime}/-1`
      }

      // ! download blob
      axios.get(url).then(({ data }) => {
        if (data.status === 'ok') {
          // window.location.replace(data.fileUrl)
          window.location.replace(data.fileUrl)

          // axios({
          //   url: data.fileUrl, //your url
          //   // url: '${process.env.VUE_APP_BILLS_API_URL}/exports/bills_20231116_011001.xlsx', //your url
          //   method: 'GET',
          //   responseType: 'blob', // important
          // }).then((response) => {
          //   // create file link in browser's memory
          //   const href = URL.createObjectURL(response.data);
          //
          //   // create "a" HTML element with href to file & click
          //   const link = document.createElement('a');
          //   link.href = href;
          //   link.setAttribute('download', data.fileName); //or any other extension
          //   document.body.appendChild(link);
          //   link.click();
          //
          //   // clean up "a" element & remove ObjectURL
          //   document.body.removeChild(link);
          //   URL.revokeObjectURL(href);
          //
          // });

          this.$notify({
            group: 'notification',
            type: 'success',
            title: 'Export success',
          })
        }
      })
    },
  },
}
</script>

<style>
.vc-container {
  font-family: 'Poppins', sans-serif !important;
}
</style>
